import { ResponsiveService } from '../../../core/services/responsive.service';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewChecked {
  activedComponent;
  route = '';
  needToCheckTwoFactor = true;

  isMobile = this.responsiveService.mobileG();
  isHelpContainer = false;

  isChatOpened = false;

  @ViewChild('layoutContainer', { static: true })
  layoutContainer: ElementRef<HTMLDivElement>;

  constructor(
    private responsiveService: ResponsiveService,
    private renderer: Renderer2,
    public router: Router,
    private eventService: EventService
  ) {
    this.eventService.on('authIrisClose', () => {
      this.isChatOpened = false;
    });
  }

  ngOnInit(): void {
    this.checkViewportSize();
    if (this.router.url === '/profile/mobile-help-menu') {
      this.isHelpContainer = true;
    }
    this.eventRute();
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngAfterViewChecked() {
    // Esto es para contrarrestar el estilo que le pone el anuncio de google al layout container
    this.renderer.setStyle(
      this.layoutContainer.nativeElement,
      'min-height',
      '0'
    );
  }

  onComponentActivate(component) {
    this.activedComponent = component;
  }

  onResize() {
    this.isMobile = this.responsiveService.mobileG();
    this.checkViewportSize();
  }

  checkViewportSize() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty('--vh', `${vh}px`);
    const viewportHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    document.documentElement.style.setProperty(
      '--vh',
      `${viewportHeight * 0.01}px`
    );
  }

  openChat() {
    this.isChatOpened = true;
  }

  /**
   * Activa la bandera para añadir el color negro y modificar el tamaño del contenedor
   */
  eventRute() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url; // Obtén la ruta actual
        if (currentRoute === '/profile/mobile-help-menu') {
          this.isHelpContainer = true;
        } else {
          this.isHelpContainer = false;
        }
      }
    });
  }
}
