<div class="navbar-container" (window:resize)="onResize()">

  <div class="navbar" [ngClass]="{'mireia-bg': mireia}" #navbar>

    <!-- <div class="logo-container" routerLink="">
      <img src="../../../../assets/images/main/header/logo.svg" alt="EyesCloud3D logo">
    </div> -->

    <div class="he-in" *ngIf="!mireia">
      <img *ngIf="privateUrlRemoteService.privateUrl && privateUrlRemoteService.privateComp !== ''"
        [src]="'assets/images/private-domain/navbar-white-' + privateUrlRemoteService.privateComp +'.svg'"
        alt="EyesCloud3D" class="text-logo" routerLink="">
      <a routerLink=""> <img *ngIf="!privateUrlRemoteService.privateUrl || privateUrlRemoteService.privateComp === ''"
          src="../../../../assets/images/main/header/new-logo.svg" alt="EyesCloud3D" class="text-logo"></a>
    </div>
    <img *ngIf="mireia" src="/assets/images/main/header/mireia-logo.webp" alt="EyesCloud3D"
      class="text-logo mireia-logo" routerLink="">


    <div class="flex">
      <div *ngIf="!mireia" class="new-model-container" routerLink="/new3d" [innerHTML]="'header_Data1' | i18n"></div>

      <div *ngIf="!mireia" class="gallery-container" routerLink="/gallery" [innerHTML]="'header_Data2' | i18n"></div>

      <div *ngIf="!mireia" class="separator"></div>
      <div class="language-selector-burguer-menu-movil">
        <div class="language-selector">
          <app-language-selector [footer]="false"></app-language-selector>
        </div>
        <div class="burguer-menu" (click)="openBurguerMenu()" *ngIf="isTableOrPc()" #burgerMenu>
          <div class="burguer-line"></div>
          <div class="burguer-line"></div>
          <div class="burguer-line"></div>
        </div>
        <div class="burguer-options" *ngIf="isBurguerMenuOpen" #burgerOptions>
          <div class="burguer-options-first-row">
            <div class="title-burguer-menu" [innerHTML]="'header_Data6'|i18n"></div>
            <img src=" assets/images/viewers/Close-black.svg" (click)="openBurguerMenu()">
          </div>
          <div class="burguer-options-second-row" [innerHTML]="'header_Data7'|i18n"
            (click)="navigateFromBurguerMenu('account')"></div>
          <div class="burguer-options-third-row" [innerHTML]="'header_Data8'|i18n"
            (click)="navigateFromBurguerMenu('help')"></div>
          <div class="burguer-separator"></div>
          <div class="burguer-options-fourth-row" (click)="logout()">
            <img src="assets/images/navbar/burguer-menu/log-out.svg">
            <div class="close-burguer-menu" [innerHTML]="'header_Data5'|i18n"></div>
          </div>
        </div>
      </div>
      <div class="account-container" (click)="openAccountPanel()" #toggleButton *ngIf="!mireia">
        <img class="user-avatar" *ngIf="user?.avatar" [src]="user.avatar" />
        <p *ngIf="!user?.avatar">{{user?.profile.name.charAt(0)}}</p>
      </div>
    </div>

    <div class="account-panel" *ngIf="isAccountPanelOpen" #menu>
      <div class="first-container">
        <div class="avatar-container">
          <img class="user-avatar" *ngIf="user?.avatar" [src]="user.avatar" />
          <p *ngIf="!user?.avatar">{{user?.profile.name.charAt(0)}}</p>
          <div class="ico-container" (click)="editAvatar()">
            <img src="../../../../assets/images/main/header/edit-icon.svg" alt="Icono lápiz editar avatar"
              class="edit-avatar">
          </div>
        </div>
        <div class="text-container">
          <p class="user-name">{{user?.profile.name + (user?.profile.surname ? ' ' + user?.profile.surname : '')}}</p>
          <p class="user-email">{{user?.email}}</p>
        </div>
      </div>
      <div class="second-container">
        <div class="go-to-config" (click)="closeAccountPanel()" routerLink="/profile/personal-data">
          <p [innerHTML]="'header_Data3' | i18n"></p>
        </div>
        <a class="go-to-help" href="https://eyescloud3d.com/ayuda" target="_blank">
          <p [innerHTML]="'header_Data4' | i18n"></p>
        </a>
      </div>
      <div class="dialog-separator"></div>
      <div class="logout-container" (click)="logout()">
        <img src="../../../../assets/images/main/header/logout-icon.svg" alt="Icono para cerrar la sesión"
          class="logout-ico">
        <p [innerHTML]="'header_Data5' | i18n"></p>
      </div>
    </div>

  </div>

</div>
