import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { ResponsiveService } from '../../../core/services/responsive.service';
import { StorageService } from 'src/app/models/storage/storage.service';
import { SecurityTokenStorage } from 'src/app/services/security/security-token-storage';
import { UserToken } from '../../../models/security/user-token';
import { AuthenticationService } from '../../../services/security/authentication.service';
import { AppConfigService } from '../../../services/config/app-config.service';
import { EventService } from 'src/app/core/services/event.service';
import { EventData } from 'src/app/core/models/event-data';
import { PrivateUrlRemoteService } from 'src/app/core/services/remote/private-url/private-url-remote.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommsService } from 'src/app/core/services/comms.service';
import { UserRemoteService } from 'src/app/core/services/remote/user/user.remote.service';
import { User } from '../../models/user/user';
import { CategoriesService } from 'src/app/core/services/categories/categories.service';
import { url } from 'inspector';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  permissionGuard: string;
  logged = false;
  loggedUser: any;
  isPublicApp = true;
  privateAppName = '';
  srcPrivate = '';
  // Aplica ciertos estilos según si eres la empresa mireia o no.
  mireia = false;
  /**
   * Variable para saber si el desplegable de la cuenta está abierto
   */
  isAccountPanelOpen = false;
  user: User;
  isBurguerMenuOpen: Boolean = false;
  /**
   * Verifica si el dispositivo tiene resolución móvil
   */
  isMobile =
    this.responsiveService.newMobile() && !this.responsiveService.newTablet();

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  @ViewChild('burgerOptions ') burgerOptions: ElementRef;
  @ViewChild('burgerMenu ') burgerMenu: ElementRef;
  @ViewChild('navbar') navbar: ElementRef;
  constructor(
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    public responsiveService: ResponsiveService,
    private storageService: StorageService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private commsService: CommsService,
    private renderer: Renderer2,
    public privateUrlRemoteService: PrivateUrlRemoteService,
    private router: Router,
    private categoriesService: CategoriesService,
    private cd: ChangeDetectorRef,
    private i18nService: I18nService
  ) {
    /**
     * This events get called by all clicks on the page
     */
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.toggleButton.nativeElement.contains(e.target) &&
        this.menu &&
        !this.menu.nativeElement.contains(e.target)
      ) {
        this.isAccountPanelOpen = false;
      }
    });
  }

  ngOnInit() {
    this.commsService.getIsMireia().subscribe((value) => {
      this.mireia = value;
    });
    this.loggedUser = this.securityTokenStorage.getObjectValue();
    this.isPublicApp = environment.isPublicApp;
    this.privateAppName = environment.privateAppName;
    this.srcPrivate =
      '/assets/images/standalone/' + this.privateAppName + '.svg';
    this.logged = this.loggedUser;

    this.commsService.getCurrentUser().subscribe((user) => {
      this.user = user;
    });
  }

  ngAfterViewInit() {
    this.adjustBurgerOptionsTop();
    window.addEventListener('resize', () => this.adjustBurgerOptionsTop());
  }
  adjustBurgerOptionsTop() {
    if (this.navbar && this.burgerOptions) {
      const navbarElement = this.navbar.nativeElement;
      const navbarHeight = navbarElement.offsetHeight;

      this.renderer.setStyle(
        this.burgerOptions.nativeElement,
        'top',
        `${navbarHeight + 2}px`
      );
    }
  }
  isTableOrPc() {
    return window.innerWidth < 1024 && window.innerWidth < window.innerHeight;
  }

  navigateFromBurguerMenu(index) {
    switch (index) {
      case 'help':
        const currentLanguage = this.i18nService.getCurrentLanguage().code;
        const languageMap: { [key: string]: string } = {
          es: 'ayuda',
          eus: 'ayuda',
          cat: 'ayuda',
          fr: 'fr/aide',
          pt: 'pt/ajuda',
        };
        const languageLabel = languageMap[currentLanguage] || 'us/help';
        window.open(`https://eyescloud3d.com/${languageLabel}`, '_blank');
        break;
      case 'account':
        window.location.href = '/profile/user-account-layout/user-rates';
        break;
      default:
        return;
    }
  }
  /**
   * Función para cerrar la sesión
   */
  async logout() {
    this.loaderService.showLoader();
    if (this.storageService.get('socialLogin')) {
      await this.delay(1000);
      this.storageService.delete('socialLogin');
    }
    this.authenticationService.logout();
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Abre el panel desplegable de la cuenta
   */
  openAccountPanel() {
    this.isAccountPanelOpen = !this.isAccountPanelOpen;
  }

  closeAccountPanel() {
    this.isAccountPanelOpen = false;
  }

  /**
   * Se ejecuta al redimensionar la ventana
   */
  onResize() {
    this.isMobile =
      this.responsiveService.newMobile() && !this.responsiveService.newTablet();
  }

  goToGallery() {
    this.categoriesService.setComesFromHome(false);
    window.location.href = '/gallery';
    // this.router.navigate(['/gallery']);
  }
  openBurguerMenu() {
    this.isBurguerMenuOpen = !this.isBurguerMenuOpen;
    this.cd.detectChanges();
    setTimeout(() => {
      if (this.isBurguerMenuOpen) {
        // Solo ajustar el top si el menú está abierto
        this.adjustBurgerOptionsTop();
      }
    }, 0);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this.burgerOptions?.nativeElement.contains(
      event.target
    );
    const clickedInsideMenu = this.burgerMenu?.nativeElement.contains(
      event.target
    );

    if (!clickedInside && this.isBurguerMenuOpen && !clickedInsideMenu) {
      this.isBurguerMenuOpen = false;
    }
  }
  editAvatar() {
    this.isAccountPanelOpen = false;
    this.router.navigate(['/profile/personal-data'], {
      queryParams: { editAvatar: 'edit-avatar' },
    });
  }
}
