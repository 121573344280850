/**
 * Enumerator that contains all download types that are available on the platform.
 */
export enum DownloadTypes {
  NONE,
  PLY,
  LAS,
  E57,
  ASC,
  GEOTIFF,
  PNG,
  OBJ,
  GLB,
  ARVR,
  STL,
  STP,
  DAE,
  BIMSERVER,
  PDF,
  TXT,
}
